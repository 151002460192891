video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  
  .about-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    background: linear-gradient(90deg, rgb(237,225,79) 20%, rgb(255, 149, 249) 100%);
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  html {
    scroll-behavior: smooth;
    
  }

  .row-container {
    display: flex;  
    flex-direction: row;
    align-items: center;
  }

  .column-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: 18px;
    font-family: 'Heiti SC', 'Menlo';
    margin-top: -120px;
    margin-right: 60px;
    margin-bottom: 10px;
  }

  .p2-container {
    color: #fff;
    font-size: 16px;
    font-family: 'Heiti SC', 'Menlo';
    margin-top: 10px;
    margin-left: 70px;
    text-align: left;
  }

  .about-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-bottom: 200px;
  }
  
  .about-container > p {
    margin-bottom: 90px;
    color: #fff;
    font-size: 20px;
    font-family: 'Heiti SC', 'Menlo';
  }
  
  .about-btns {
    margin-top: 32px;
    margin-left: 60px;
  }
  
  .about-btns .btn {
    margin: 6px;
  }
  
  .fa-linkedin {
    margin-left: 12px;
  }
  
  .pic {
      height: 500px;
      width: 37%;
      margin-right: 50px;
      margin-left: 50px;
      margin-top: -175px;
  }
  
  @media screen and (max-width: 960px) {
    .about-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }

  @media screen and (max-width: 768px) {
    .about-container > h1 {
      font-size: 50px;
      margin-top: 100px;
    }
  
    .about-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }